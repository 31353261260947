import Request from "../helpers/Request";

export async function getOrders(params) {

    let result = [],
        pagination = {};

    await Request({
        path: '/good?' + params.join('&'),
        method: 'GET',
        data: {},
        callback: response => {

            if (response) {

                result = response;
            }
        },
        headers: headers => {

            pagination = {
                currentPage: headers['x-pagination-current-page'] * 1,
                pageCount: headers['x-pagination-page-count'] * 1,
                perPage: headers['x-pagination-per-page'] * 1,
                totalCount: headers['x-pagination-total-count'] * 1,
            }
        },
        error: error => {},
    });

    return {
        result, pagination
    };
}

export async function addProduct(data, callback = null) {

    let result = [];

    await Request({
        path: `/good`,
        method: 'POST',
        data: data,
        callback: response => {

            if (response) {

                result = response;
            }
        },
        headers: headers => {},
        error: error => {

            if (callback) {

                callback(error);
            }
        },
    });

    return result;
}

export async function updateProduct(id, data, callback = null) {

    let result = [];

    await Request({
        path: `/good/${id}?expand=type`,
        method: 'PATCH',
        data: data,
        callback: response => {

            if (response) {

                result = response;
            }
        },
        headers: headers => {},
        error: error => {

            if (callback) {

                callback(error);
            }
        },
    });

    return result;
}

export async function getOrder(id) {

    let result = [];

    await Request({
        path: `/good/${id}?expand=type`,
        method: 'GET',
        data: {},
        callback: response => {

            if (response) {

                result = response;
            }
        },
        headers: headers => {},
        error: error => {},
    });

    return result;
}