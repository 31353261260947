import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import MainLayout from '../layouts/Main';

const ProtectedComponent = (WrappedComponent) => {

    class WithAuthorization extends React.Component {

        render() {

            const { isAuthorized } = this.props;

            if (!isAuthorized) {

                return <Redirect to='/login' />;
            }

            return (
                <MainLayout {...this.props}>
                    <WrappedComponent {...this.props}/>
                </MainLayout>
            );
        }
    }

    const mapStateToProps = (state) => {

        return {
            isAuthorized: localStorage.user_id
        }
    };

    return connect(mapStateToProps)(WithAuthorization);
};

export default ProtectedComponent;