import axios from 'axios';
import { saveState } from '../store/localstorage'
import Request from "../helpers/Request";

export async function actionLogin(params) {

    let result = false;
    await Request({
        baseUrl: 'https://auth.super-opt.com',
        path: '/oauth2/token',
        method: 'POST',
        data: params,
        isAuth: true,
        callback: response => {

            if (response.access_token) {

                saveState(response);

                result = true;
            }
        },
        headers: headers => {},
        error: error => {},
    });

    return result;
}