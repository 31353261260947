import axios from 'axios';
import { saveState } from '../store/localstorage'

export default async function Request(params) {

    const baseUrl = 'https://api.super-opt.com/v1';

    if (!params.isAuth) {

        checkTokenTime();
    }

    await axios({
        method: params.method,
        url: params.baseUrl ? `${params.baseUrl}${params.path}` : `${baseUrl}${params.path}`,
        data: params.data,
        headers: {
            Authorization: `Bearer ${localStorage.access_token}`,
        }
    })
    .then((response) => {

        if (response.status) {

            params.callback(response.data);
            params.headers(response.headers);
        }
    })
    .catch(error => {

        params.error(error);
    });
}

function getToken(token) {

    Request({
        baseUrl: 'https://auth.super-opt.com',
        path: '/oauth2/token',
        data: {
            access_token: token
        },
        method: 'POST',
        isAuth: true,
        callback: function(response) {

            if (response.access_token) {

                saveState(response);
            }
            else {

                exit();
            }
        },
        headers: headers => {},
        error: error => {},
    })
}

function checkRefreshToken() {

    let refresh_token = localStorage.refresh_token;

    if (!refresh_token) {

        exit();
    }

    return refresh_token;
}

function checkTokenTime() {

    let expires_in = localStorage.expires_in;

    if (!expires_in) {

        exit();
    }

    let currentTime = new Date();

    if ((expires_in - currentTime.getTime()) < 300000) {

        getToken(checkRefreshToken());
    }
}

function exit() {

    localStorage.clear();
    //redirect (change state)
}