import React, { Component } from "react";
import { connect } from 'react-redux';

import { login } from "../../../store/user/auth/actions";
import Auth from "./Auth";

class AuthContainer extends Component {

    render() {

        return <Auth {...this.props} />
    }
}

const mapStateToProps = state => {

    return {
        user: state.auth.user,
    }
}

const mapDispatchToProps = {
    login,
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthContainer)