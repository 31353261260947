import Orders from "../components/orders/Orders";
import AuthContainer from '../components/user/auth/AuthContainer'
import Order from "../components/orders/Order";

export const routerStructure = [
    {
        'path': '/',
        'isDefault': true,
        'isPrivate': true,
        'isNavbar': true,
        'name': 'Мои заказы',
        'container': Orders,
        'iconHTML': `
            <svg className="ico ico-mono-sidebar-menu-1">
                <use xlinkHref="/static/img/sprite-mono.svg#ico-mono-sidebar-menu-1"></use>
            </svg>
        `
    },
    {
        'path': '/product/:id',
        'isDefault': false,
        'isPrivate': true,
        'isNavbar': false,
        'name': 'Товар',
        'container': Order,
    },
    {
        'path': '/product',
        'isDefault': false,
        'isPrivate': true,
        'isNavbar': false,
        'name': 'Товар',
        'container': Order,
    },
    {
        'path': '/login',
        'isDefault': false,
        'isPrivate': false,
        'isNavbar': true,
        'name': 'Вход',
        'container': AuthContainer
    },
];

export default {
    routerStructure,
}