import { actionLogin } from '../../../models/User'

export const LOG_IN = 'LOG_IN'
export const LOG_OUT = 'LOG_OUT'
export const LOG_IN_FAILURE = 'LOG_IN_FAILURE'

export function login(params, callback) {

    return async dispatch => {

        const success = await actionLogin(params);

        if (success) {

            dispatch({
                type: LOG_IN,
                payload: {
                    email: params.email,
                },
            })
            callback()
        }
        else {

            dispatch({
                type: LOG_IN_FAILURE,
                payload: {
                    errorMsg: 'Имя пользователя или пароль введены неверно!',
                },
                error: true,
            })
        }
    }
}