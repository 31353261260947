export const loadState = () => {

    try {

        const serializedState = localStorage.getItem('state');

        if (serializedState === null) {

            return undefined;
        }

        return JSON.parse(serializedState);
    } catch (err) {

        return undefined;
    }
};

export const saveState = (state) => {

    try {

        for (let i in state) {

            if (i == 'expires_in') {

                state[i] = state[i]*1000 + new Date().getTime();
            }

            localStorage.setItem(i, state[i]);
        }
    } catch {}
};