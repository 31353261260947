import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './static/css/base.css';
import {routerStructure} from "./config/Router";
import ProtectedComponent from "./helpers/PrivateRoute";

function App() {

    return (
        <Router>
            <Switch>
                {routerStructure.map(route => {

                    const component = route.isPrivate ? ProtectedComponent(route.container) : route.container;

                    return <Route exact={route.isDefault} path={route.path} component={component} />
                })}
            </Switch>
        </Router>
    );
}

export default App;
