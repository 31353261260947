export default function TimeStamp(timestamp, full, month) {

    let months = {
            0: ' января ',
            1: ' февраля ',
            2: ' марта ',
            3: ' апреля ',
            4: ' мая ',
            5: ' июня ',
            6: ' июля ',
            7: ' авгруста ',
            8: ' сентября ',
            9: ' октября ',
            10: ' ноября ',
            11: ' декабря '
        },
        theDate = new Date(timestamp * 1000),
        full_date = theDate.getDate()+'.'+(theDate.getMonth()+1<10?('0'+(theDate.getMonth()+1)):theDate.getMonth()+1)+'.'+theDate.getFullYear(),
        full_date_width_time = full_date + ' в '+theDate.getHours()+':'+(theDate.getMinutes()<10?('0'+theDate.getMinutes()):theDate.getMinutes()),
        stringDate = theDate.getDate() + months[theDate.getMonth()] + theDate.getFullYear() + full_date_width_time.replace(full_date, '');

    return full ? full_date_width_time : (month ? stringDate : full_date);
}