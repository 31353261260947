import React, {Component} from "react";
import { Redirect } from "react-router-dom";

class Auth extends Component {

    constructor(props) {

        super(props);

        this.state = {
            email: '',
            password: '',
            redirect: false
        }
    }

    submit = event => {

        event.preventDefault();

        const { email, password } = this.state

        this.props.login(
            {
                username: email,
                password: password,
                grant_type: "password",
                client_id: "cms",
                client_secret: "cms_pass"
            },
            () => {

                this.setState({ redirect: true })
            }
        )
    }

    handleChange = e => {
        const value = e.currentTarget.value
        const fieldName = e.currentTarget.dataset.fieldName

        this.setState(prev => ({
            ...prev,
            [fieldName]: value,
        }))
    }

    render() {

        const { email, password, redirect } = this.state

        if (redirect) {

            return <Redirect to="/" />
        }


        return (
            <form className="form-signin text-center" onSubmit={this.submit}>
                <h1 className="h3 mb-3 font-weight-normal">Вход</h1>
                <label htmlFor="inputEmail" className="sr-only">Логин</label>
                <input type="text"
                       id="inputEmail"
                       value={email}
                       className="form-control"
                       placeholder="Логин"
                       data-field-name={'email'}
                       onChange={this.handleChange}
                       required
                       autoFocus />
                <label htmlFor="inputPassword" className="sr-only">Пароль</label>
                <input type="password"
                       value={password}
                       data-field-name={'password'}
                       onChange={this.handleChange}
                       className="form-control"
                       placeholder="Пароль"
                       required />
                <button className="btn btn-lg btn-primary btn-block" type="submit">Войти</button>
            </form>
        )
    }
}

export default Auth