import Request from "../helpers/Request";

export default async function renewYml() {

    let result = [];

    await Request({
        path: `/yml`,
        method: 'POST',
        data: {},
        callback: response => {

            if (response) {

                result = response;
            }
        },
        headers: headers => {},
        error: error => {},
    });

    return result;
}