import React, {Component} from "react";
import {getOrder, getOrders, updateProduct} from '../../models/Order';
import renewYml from '../../models/Yml';
import {BrowserRouter as Router, Link} from "react-router-dom";
import Pagination from "react-js-pagination";
import renderHTML from 'react-render-html';
import TimeStamp from '../../helpers/TimeStamp';
import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap';
import FlashMessage from "react-flash-message";

class Orders extends Component {

    constructor(props) {

        super(props);

        this.state = {
            products: [],
            filter: {
                'name': '',
                'q[status][equal]': ''
            },
            pagination: {
                currentPage: 1,
                pageCount: 1,
                perPage: 10,
                totalCount:1,
            },
            statuses: {},
            counters: {
                all: 0,
                active: 0,
                waiting: 0
            },
            activeTabKey: 0,
            editedProduct: {},
            flashMassge: false
        }
    }

    handlePageChange(pageNumber) {

         getOrders([
             `page=${pageNumber}`,
             'sort=-id',
             'expand=type,priceHistory'
         ]).then(
             result => {
                    this.setState({
                        orders: result.result,
                        pagination: result.pagination
                    })
                }
         );
    }

    componentDidMount() {

        getOrder('statuses').then(
            result => {
                this.setState({
                    statuses: result,
                    order: this.state.order
                })
            }
        );

        getOrders([
            'page=1',
            'sort=-id',
            'q[status][equal]=active',
            'expand=type,priceHistory'
        ]).then(
            result => {
                this.setState({
                    products: result.result,
                    pagination: result.pagination
                })
            }
        );

        this.getOrderByStatus('all');

        this.getOrderByStatus('active');

        this.getOrderByStatus('waiting');

        this.getOrderByStatus('has_erros');

        this.getOrderByStatus('moderated');

        document.addEventListener('click', function (e) {

            if (e.target.classList.value != 'options') {

                let els = document.querySelectorAll('.options>ul.active'),
                    elsArray = [...els];

                for (let i in elsArray) {

                    elsArray[i].classList.remove('active');
                }
            }
        });
    }

    getOrderByStatus = (type, isAction = false, target) => {

        let types = {
            'all': [
                'per-page=10',
                'q[status][in]=active,ready_for_sale',
                'expand=type'
            ],
            'active': [
                'per-page=10',
                'q[status][equal]=active',
                'expand=type'
            ],
            'waiting': [
                'per-page=10',
                'q[status][equal]=ready_for_sale',
                'expand=type'
            ],
            'has_erros': [
                'per-page=10',
                'q[status][equal]=has_erros',
                'expand=type'
            ],
            'moderated': [
                'per-page=10',
                'q[status][equal]=moderated',
                'expand=type'
            ]
        }

        getOrders(types[type]).then(
            result => {

                if (isAction) {

                    this.setState({
                        products: result.result,
                        pagination: result.pagination,
                        activeTabKey: target.getAttribute('data-key')
                    });
                }
                else {

                    this.setState(prevState => ({
                        counters: {...prevState.counters, [type]: result.pagination.totalCount}
                    }));
                }
            }
        );
    }

    renderStatuses = (statuses) => {

        let statusHTML = `<option value="">Статус</option>`;

        for (let i in statuses) {

            statusHTML += `<option value="${i}">${statuses[i]}</option>`;
        }

        return statusHTML;
    }

    findProducts = event => {

        event.preventDefault();

        const {name, value} = event.target;

        this.setState(prevState => ({
            filter: {...prevState.filter, [name]: value},
        }));

        let filters = [
            'page=1',
            'sort=-id',
            'expand=type'
        ];

        for (let i in this.state.filter) {

            if (this.state.filter[i]) {

                filters.push(`${i}=${this.state.filter[i]}`)
            }
        }

        getOrders(filters).then(
            result => {
                this.setState({
                    products: result.result,
                    pagination: result.pagination
                })
            }
        );
    }

    showFieldEditor = (e) => {

        const value = e.currentTarget.dataset.productValue;
        const fieldName = e.currentTarget.dataset.productField;
        const productID = e.currentTarget.dataset.productId;

        let state = this.state.editedProduct;

        if (!state[productID]) {

            state[productID] = {
                [fieldName]: value
            }
        }
        else {

            state[productID][fieldName] = value;
        }

        let target = e.currentTarget;

        this.setState({
            editedProduct: state,
        }, function() {

            target.querySelector('input').focus();
        });
    }

    handleChange = (e) => {
        const value = e.currentTarget.value;
        const fieldName = e.currentTarget.dataset.productField;
        const productID = e.currentTarget.dataset.productId;

        let editedProduct = this.state.editedProduct;

        editedProduct[productID][fieldName] = value;

        this.setState({
            editedProduct: editedProduct
        });
    }

    handleFocusOut = (e) => {

        const value = e.currentTarget.value;
        const oldValue = e.currentTarget.dataset.currentValue;
        const fieldName = e.currentTarget.dataset.productField;
        const productID = e.currentTarget.dataset.productId;

        let editedProduct = this.state.editedProduct;

        if (oldValue == value) {

            delete editedProduct[productID][fieldName];

            if (!Object.keys(editedProduct[productID]).length) {

                delete editedProduct[productID];
            }
        }

        this.setState({
            editedProduct: editedProduct
        })
    }

    renew = event => {

        renewYml().then(
            result => {

                alert('Файл обвновлен!');
            }
        );
    }

    cancelChanges = event => {

        this.setState({
            editedProduct: {}
        })
    }

    applyChanges = event => {

        let products = this.state.products;

        for (let i in this.state.editedProduct) {

            let data = this.state.editedProduct[i];

            for (let j in products) {

                if (products[j].id == i) {

                    products[j] = {...products[j], ...data};
                }
            }

            updateProduct(i, data).then();
        }

        this.setState({
            products: products,
            editedProduct: {},
            flashMassge: 'Товары успешно сохранены!'
        });

        window.setTimeout(() => {
            this.setState({
                flashMassge: false
            });
        }, 3000);
    }

    optionHandler = event => {

        event.currentTarget.querySelector('ul').classList.add('active');
    }

    putProductToArchive = event => {


        const productID = event.currentTarget.dataset.poductId;

        updateProduct(productID, {
            status: 'moderated'
        }).then(
            result => {

                getOrders([
                    'page=1',
                    'sort=-id',
                    'q[status][equal]=active',
                    'expand=type,priceHistory'
                ]).then(

                    result => {
                        this.setState({
                            products: result.result,
                            pagination: result.pagination
                        })
                    }
                );
            }
        );
    }

    render() {

        const { products, pagination, filter, statuses } = this.state;

        return (
            <Container>

                {
                    this.state.flashMassge &&
                    <FlashMessage duration={3000} persistOnHover={true}>
                        <div className="flash-message">
                            <div>
                                <p>{this.state.flashMassge}</p>
                            </div>
                        </div>
                    </FlashMessage>
                }

                <h2>Каталог</h2>

                <Button variant="warning" onClick={this.renew}>Обвноить YML-файл</Button>

                <Link className="btn btn-secondary" to={`/product`} style={{ fontSize: '16px', marginLeft: '10px' }}>Добавить товар</Link>

                <ul className='tabs'>
                    <li onClick={(event) => {this.getOrderByStatus('all', true, event.target)}} data-key='0' className={this.state.activeTabKey == '0' ? 'active' : ''}>Все товары <i>{this.state.counters.all}</i></li>
                    <li onClick={(event) => {this.getOrderByStatus('active', true, event.target)}} data-key='1' className={this.state.activeTabKey == '1' ? 'active' : ''}>В продаже <i>{this.state.counters.active}</i></li>
                    <li onClick={(event) => {this.getOrderByStatus('waiting', true, event.target)}} data-key='2' className={this.state.activeTabKey == '2' ? 'active' : ''}>Готовы к продаже <i>{this.state.counters.waiting}</i></li>
                    <li onClick={(event) => {this.getOrderByStatus('has_erros', true, event.target)}} data-key='3' className={this.state.activeTabKey == '3' ? 'active' : ''}>С ошибками <i>{this.state.counters.has_erros}</i></li>
                    <li onClick={(event) => {this.getOrderByStatus('waiting', true, event.target)}} data-key='4' className={this.state.activeTabKey == '4' ? 'active' : ''}>Снят с продажи <i>{this.state.counters.waiting}</i></li>
                    <li onClick={(event) => {this.getOrderByStatus('moderated', true, event.target)}} data-key='5' className={this.state.activeTabKey == '5' ? 'active' : ''}>В архиве <i>{this.state.counters.moderated}</i></li>
                </ul>

                <Form>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Название товара или артикул</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    placeholder="Название товара"
                                    onChange={this.findProducts}
                                    value={filter['name']}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
                <div className="table-responsive">
                    <Table id="products-table" striped bordered hover>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Название и тип</th>
                                <th>Артикул</th>
                                <th style={{width: '130px'}}>Цена</th>
                                <th style={{width: '130px'}}>На складе</th>
                                <th style={{width: '140px'}}>Статус</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {products.map((product, key) => {

                                return (
                                    <tr key={key}>
                                        <td><img src={product.media.preview} alt={product.name} style={{'width': '75px'}} /></td>
                                        <td>
                                            <Link target="_blank" to={`/product/${product.id}`}>
                                                {product.name}
                                            </Link>
                                            <br/><i>{product.type.name}</i>
                                        </td>
                                        <td>{product.articul}</td>
                                        <td data-product-id={product.id} data-product-field="price" data-product-value={this.state.editedProduct[product.id] && this.state.editedProduct[product.id].price ? this.state.editedProduct[product.id].price : product.price} onClick={this.showFieldEditor}>
                                            {(() => {

                                                if (this.state.editedProduct[product.id] && this.state.editedProduct[product.id].price != undefined) {

                                                    return (
                                                        <div>
                                                            <input data-product-field="price" onChange={this.handleChange} onBlur={this.handleFocusOut} type="number" data-product-id={product.id} data-current-value={product.price} value={this.state.editedProduct[product.id].price} />
                                                        </div>
                                                    )
                                                }

                                                return (
                                                    <div>{product.price}</div>
                                                )
                                            })()}

                                            <i>{product.priceHistory ? TimeStamp(product.priceHistory.created_at, false, true) : ''}</i>
                                        </td>
                                        <td data-product-id={product.id} data-product-field="stock" data-product-value={this.state.editedProduct[product.id] && this.state.editedProduct[product.id].stock ? this.state.editedProduct[product.id].stock : product.stock} onClick={this.showFieldEditor}>
                                            {(() => {

                                                if (this.state.editedProduct[product.id] && this.state.editedProduct[product.id].stock != undefined) {

                                                    return (
                                                        <div>
                                                            <input data-product-field="stock" onChange={this.handleChange}  onBlur={this.handleFocusOut} type="number" data-product-id={product.id} data-current-value={product.stock} value={this.state.editedProduct[product.id].stock} />
                                                        </div>
                                                    )
                                                }

                                                return (
                                                    <div>{product.stock}</div>
                                                )
                                            })()}
                                        </td>
                                        <td>{this.state.statuses[product.status]}</td>
                                        <td>
                                            <div className="options" onClick={this.optionHandler}>
                                                •••
                                                <ul>
                                                    <li><Link target="_blank" to={`/product/${product.id}`}>Редактировать товар</Link></li>
                                                    <li><a target="_blank" href={product.goods_url}>Смотреть карточку</a></li>
                                                    <li onClick={this.putProductToArchive} data-poduct-id={product.id}>Перенести в архив</li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
                <div>
                    <Pagination
                        activePage={pagination.currentPage}
                        itemsCountPerPage={pagination.perPage}
                        totalItemsCount={pagination.totalCount}
                        pageRangeDisplayed={4}
                        onChange={this.handlePageChange.bind(this)}
                        size="sm"
                    />
                </div>
                <div className={Object.keys(this.state.editedProduct).length ? "bottom-panel active" : "bottom-panel"}>
                    <div className="bottom-panel-control">
                        <Button variant="secondary" onClick={this.cancelChanges}>Отменить</Button>
                        <Button variant="warning" onClick={this.applyChanges}>Сохранить</Button>
                    </div>
                </div>
            </Container>
        )

    }
}

export default Orders