import React, {Component} from "react";
import {addProduct, getOrder, updateProduct} from '../../models/Order';
import { getTypeByName } from '../../models/Type';
import TimeStamp from '../../helpers/TimeStamp';
import { FaCopy, FaTrash } from 'react-icons/fa';
import renderHTML from "react-render-html";
import AsyncSelect from 'react-select/async';
import { Redirect } from 'react-router-dom';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import FineUploaderTraditional from 'fine-uploader-wrappers';
import 'react-fine-uploader/gallery/gallery.css';
import Gallery from 'react-fine-uploader';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import { Tooltip } from '@material-ui/core';
import arrayMove from 'array-move';
import FlashMessage from 'react-flash-message';
import ReactFancyBox from 'react-fancybox';
import 'react-fancybox/lib/fancybox.css';

class Order extends Component {

    constructor(props) {

        super(props);

        this.state = {
            order: {
                articul: null,
                name: null,
                //price: null,
                status: 'moderated',
                id_type: null,
                media: {
                    preview: null,
                    photos: []
                },
                description: null,
                barcode: null,
                barcode_2: null,
                model: null,
                goods_id: null,
                brand: null,
                supplier_articul: null,
                characteristics: {
                    weight: null,
                    width: null,
                    height: null,
                    length: null,
                },
                params: []
            },
            param: {
                name: '',
                value: '',
            },
            statuses: {},
            flashMassge: false,
            requestSuccess: true
        }

        this.uploader = new FineUploaderTraditional({
            options: {
                chunking: {
                    enabled: false
                },
                request: {
                    endpoint: 'https://file.super-opt.com/v1/uploads?accessToken=' + localStorage.access_token,
                    inputName: 'image'
                },
            }
        });
    }

    componentDidMount() {

        getOrder('statuses').then(
            result => {
                this.setState({
                    statuses: result,
                    order: this.state.order
                })
            }
        );

        if (this.props.match.params.id) {

            getOrder(this.props.match.params.id).then(
                result => {
                    this.setState({
                        order: result,
                        statuses: this.state.statuses,
                    });
                }
            );
        }

        this.uploader.on('complete', (id, name, response) => {

            if (response.server) {

                let media = this.state.order.media;

                media.photos.push(response.server + response.patch);

                function onlyUnique(value, index, self) {
                    return self.indexOf(value) === index;
                }

                media.photos = media.photos.filter(onlyUnique);

                this.setState(prevState => ({
                    order: {...prevState.order, media: media}
                }));
            }
        });
    }

    submit = event => {

        event.preventDefault();

        let errorHandler = error => {

            if (error.response) {

                let errorMessage = [];

                for (let i in error.response.data) {

                    errorMessage.push(error.response.data[i].message);
                }

                this.setState({
                    flashMassge: errorMessage.join('<br />'),
                    requestSuccess: false
                });

                window.setTimeout(() => {
                    this.setState({
                        flashMassge: false,
                        requestSuccess: true
                    });
                }, 3000);
            }
        }

        if (!this.props.match.params.id) {

            addProduct(this.state.order, errorHandler).then(
                result => {
                    if (result.id) {
                        this.setState({
                            redirect: result.id,
                        });
                    }
                }
            )
        }
        else {

            updateProduct(this.props.match.params.id, this.state.order, errorHandler).then(
                result => {

                    if (result.id) {

                        this.setState({
                            order: result,
                            flashMassge: 'Товар успешно сохранен!',
                            requestSuccess: true
                        });

                        window.setTimeout(() => {
                            this.setState({
                                flashMassge: false,
                                requestSuccess: true
                            });
                        }, 3000);
                    }
                }
            )
        }
    }

    renderStatuses = (statuses, order) => {

        let statusHTML = '';

        for (let i in statuses) {

            statusHTML += `<option ${order.status == i ? "selected='selected'" : ''} value="${i}">${statuses[i]}</option>`;
        }

        return statusHTML;
    }

    async loadOptions(inputValue) {

        let data = await getTypeByName(inputValue),
            result = [];

        for (let i in data) {

            result.push({
                label: data[i].name,
                value: data[i].id
            });
        }

        return result;
    };

    onTodoChange = event => {

        const {name, value} = event.target;

        this.setState(prevState => ({
            order: {...prevState.order, [name]: value}
        }));
    }

    handleOnChange = data => {

        let type = {
            name: data.label,
            id: data.id
        }

        this.setState(prevState => ({
            order: {...prevState.order, 'id_type': data.value, 'type': type}
        }));
    };

    characteristicsChange = event => {

        const {name, value} = event.target;

        let characteristics = this.state.order.characteristics;

        characteristics[name] = value;

        this.setState(prevState => ({
            order: {...prevState.order, 'characteristics': characteristics}
        }));
    }

    removeImage = event => {

        let element = event.target.dataset.photoId ? event.target : event.target.parentElement,
            media = this.state.order.media;

        media.photos.splice(parseInt(element.dataset.photoId), 1);

        this.setState(prevState => ({
            order: {...prevState.order, media: media}
        }));
    }

    createPreview = event => {

        let media = this.state.order.media;

        media.preview = event.target.parentElement.parentElement.getAttribute('data-id');

        this.setState(prevState => ({
            order: {...prevState.order, media: media}
        }));
    }

    onSortEnd = ({oldIndex, newIndex}) => {

        let media = this.state.order.media;

        media.photos = arrayMove(this.state.order.media.photos, oldIndex, newIndex);

        this.setState(prevState => ({
            order: {...prevState.order, media: media}
        }));
    };

    addToClipboard = event => {

        event.target.closest('div').querySelector('input').select();

        document.execCommand('copy');

        this.setState({
            flashMassge: 'Ссылка скопирована!',
            requestSuccess: true,
        });

        window.setTimeout(() => {
            this.setState({
                flashMassge: false,
                requestSuccess: true,
            });
        }, 3000);
    }

    /*

    onParamChange = (event, index) => {

        const {name, value} = event.target;

        let params = this.state.order.params;

        params[index][name] = value;

        this.setState(prevState => ({
            order: {...prevState.order, 'params': params}
        }));
    }

    removeParam = (event, index) => {

        let params = this.state.order.params;

        params.splice(index, 1);

        this.setState(prevState => ({
            order: {...prevState.order, 'params': params}
        }));
    }

    addParam = event => {

        event.preventDefault();

        let params = this.state.order.params;

        params.push(this.state.param);

        this.setState(prevState => ({
            order: {...prevState.order, 'params': params},
            param: {
                name: '',
                value: '',
            }
        }));
    }

    onNewParamChange = event => {

        const {name, value} = event.target;

        this.setState(prevState => ({
            param: {...prevState.param, [name]: value}
        }));
    }
    */

    render() {

        if (this.state.redirect) {

            return <Redirect to={`/product/${this.state.redirect}`} />;
        }

        const { order, statuses, param } = this.state;

        const SortableItem = SortableElement(({value, sortIndex}) => {

            let photoText = 'Превью';

            if (sortIndex > 0 && sortIndex < 5) {

                photoText = 'Дополнительное фото ' + sortIndex;
            }
            else if (sortIndex > 4) {

                photoText = 'Дополнительное фото ';
            }

            return (
                <div data-id={value} className="orderable-element">
                    <div className="photo-preview">
                        <ReactFancyBox
                            thumbnail={value}
                            image={value}/>
                    </div>
                    <div>
                        <p>{photoText} {sortIndex < 5 ? '' : <Tooltip title="Изображения, которые вы не можете подгружать на маркетплейс из-за ограничения количества фото: 5">
                            <Button className="custom-tooltip">?</Button>
                        </Tooltip>}
                        </p>
                        <input className="form-control" type="text" value={value}/>
                        <FaCopy className="copy-photo" onClick={this.addToClipboard} />
                        <FaTrash data-photo-id={sortIndex} className="delete-photo" onClick={this.removeImage} />
                    </div>
                </div>
            )
        });

        const SortableList = SortableContainer(({items}) => {

            return (
                <div className="sortable-photos">
                    {items.map((value, index) => (
                        <SortableItem key={`item-${value}`} sortIndex={index} index={index} value={value} />
                    ))}
                </div>
            );
        });

        return (
            <Container>
                {
                    this.state.flashMassge &&
                    <FlashMessage duration={3000} persistOnHover={true}>
                        <div className={"flash-message " + (this.state.requestSuccess ? "success" : "error")}>
                            <div>
                                <p>{renderHTML(this.state.flashMassge)}</p>
                            </div>
                        </div>
                    </FlashMessage>
                }

                <Form style={{marginBottom: '40px'}} onSubmit={this.submit} className="product-from">
                    <Row>
                        <Col>
                            <h5>Основная информация</h5>

                            <Form.Group>
                                <Row>
                                    <Col><Form.Label>
                                        Goods ID
                                        <Tooltip title="ID берется после формирования товара на goods.ru">
                                            <Button className="custom-tooltip">?</Button>
                                        </Tooltip>
                                    </Form.Label></Col>
                                    <Col xs={8}>
                                        <Form.Control
                                            type="text"
                                            name="goods_id"
                                            className="form-control"
                                            placeholder="Goods ID"
                                            onChange={this.onTodoChange}
                                            value={order.goods_id}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>

                            <Form.Group>
                                <Row>
                                    <Col><Form.Label>Артикул*</Form.Label></Col>
                                    <Col xs={8}>
                                        <Form.Control
                                            type="text"
                                            name="articul"
                                            className="form-control"
                                            placeholder="Артикул"
                                            onChange={this.onTodoChange}
                                            value={order.articul}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>

                            <Form.Group>
                                <Row>
                                    <Col><Form.Label>Название товара*</Form.Label></Col>
                                    <Col xs={8}>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            className="form-control"
                                            placeholder="Название товара"
                                            onChange={this.onTodoChange}
                                            value={order.name}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>

                            <Form.Group>
                                <Row>
                                    <Col><Form.Label>Категория</Form.Label></Col>
                                    <Col xs={8}>
                                        <AsyncSelect
                                            cacheOptions
                                            defaultOptions
                                            name="id_type"
                                            onChange={this.handleOnChange}
                                            loadOptions={this.loadOptions}
                                            value={{
                                                value: order.type ? order.type.id : null,
                                                label: order.type ? order.type.name : null
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>

                            <Form.Group>
                                <Row>
                                    <Col><Form.Label>Бренд*</Form.Label></Col>
                                    <Col xs={8}>
                                        <Form.Control
                                            type="text"
                                            name="brand"
                                            className="form-control"
                                            placeholder="Бренд"
                                            onChange={this.onTodoChange}
                                            value={order.brand}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>

                            <Form.Group>
                                <Row>
                                    <Col><Form.Label>Артикул поставщика</Form.Label></Col>
                                    <Col xs={8}>
                                        <Form.Control
                                            type="text"
                                            name="supplier_articul"
                                            className="form-control"
                                            placeholder="Артикул поставщика"
                                            onChange={this.onTodoChange}
                                            value={order.supplier_articul}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>

                            <Form.Group>
                                <Row>
                                    <Col>
                                        <Form.Label>Модель
                                            <Tooltip title=" Данное поле обязательно заполнять для размерных товаров, так как на его основе происходит автоматическое объединение в одну карточку на сайте.">
                                                <Button className="custom-tooltip">?</Button>
                                            </Tooltip>
                                        </Form.Label>
                                    </Col>
                                    <Col xs={8}>
                                        <Form.Control
                                            type="text"
                                            name="model"
                                            className="form-control"
                                            placeholder="Модель"
                                            onChange={this.onTodoChange}
                                            value={order.model}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>

                            <h5>Штрихкоды</h5>

                            <Form.Group>
                                <Row>
                                    <Col><Form.Label>Штрихкод</Form.Label></Col>
                                    <Col xs={8}>
                                        <Form.Control
                                            type="text"
                                            name="barcode"
                                            className="form-control"
                                            placeholder="barcode"
                                            onChange={this.onTodoChange}
                                            value={order.barcode}
                                        />
                                    </Col>
                                </Row>

                            </Form.Group>

                            <Form.Group>
                                <Row>
                                    <Col><Form.Label>Штрихкод 2</Form.Label></Col>
                                    <Col xs={8}>
                                        <Form.Control
                                            type="text"
                                            name="barcode_2"
                                            className="form-control"
                                            placeholder="barcode"
                                            onChange={this.onTodoChange}
                                            value={order.barcode_2}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>


                            <h5>
                                Габариты
                                <Tooltip title="Допустимое значение от 1 до 500">
                                    <Button className="custom-tooltip">?</Button>
                                </Tooltip>
                            </h5>

                            <Form.Group>
                                <Row>
                                    <Col><Form.Label>Длина (см)</Form.Label></Col>
                                    <Col xs={8}>
                                        <Form.Control
                                            type="number"
                                            name="length"
                                            className="form-control"
                                            step="0.01"
                                            placeholder="Длина"
                                            onChange={this.characteristicsChange}
                                            value={order.characteristics['length']}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>

                            <Form.Group>
                                <Row>
                                    <Col><Form.Label>Ширина (см)</Form.Label></Col>
                                    <Col xs={8}>
                                        <Form.Control
                                            type="number"
                                            name="width"
                                            className="form-control"
                                            step="0.01"
                                            placeholder="Ширина"
                                            onChange={this.characteristicsChange}
                                            value={order.characteristics.width}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>

                            <Form.Group>
                                <Row>
                                    <Col><Form.Label>Высота (см)</Form.Label></Col>
                                    <Col xs={8}>
                                        <Form.Control
                                            type="number"
                                            name="height"
                                            className="form-control"
                                            step="0.01"
                                            placeholder="Высота"
                                            onChange={this.characteristicsChange}
                                            value={order.characteristics.height}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>

                            <Form.Group>
                                <Row>
                                    <Col><Form.Label>Масса (кг)</Form.Label></Col>
                                    <Col xs={8}>
                                        <Form.Control
                                            type="number"
                                            name="weight"
                                            className="form-control"
                                            step="0.01"
                                            placeholder="Масса"
                                            onChange={this.characteristicsChange}
                                            value={order.characteristics.weight}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h5>Фото</h5>

                            {/*
                            <Form.Group>
                                <Form.Label>Характеристики</Form.Label>
                                <table style={{width: '100%'}}>
                                    <thead>
                                        <tr>
                                            <td>
                                                <Form.Control
                                                    type="text"
                                                    name="name"
                                                    className="form-control"
                                                    placeholder="Характеристика"
                                                    onChange={this.onNewParamChange}
                                                    value={param.name}
                                                />
                                            </td>
                                            <td>
                                                <Form.Control
                                                    type="text"
                                                    name="value"
                                                    className="form-control"
                                                    placeholder="Значение"
                                                    onChange={this.onNewParamChange}
                                                    value={param.value}
                                                />
                                            </td>
                                            <td>
                                                <Button variant="primary" onClick={this.addParam}>Добавить</Button>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {order.params.map((element, key) => {

                                        return (
                                            <tr key={key}>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name="name"
                                                        className="form-control"
                                                        placeholder="Значение"
                                                        onChange={event => {this.onParamChange(event, key)}}
                                                        value={element.name}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name="value"
                                                        className="form-control"
                                                        placeholder="Значение"
                                                        onChange={event => {this.onParamChange(event, key)}}
                                                        value={element.value}
                                                    />
                                                </td>
                                                <td>
                                                    <Button variant="danger" onClick={event => {this.removeParam(event, key)}}>Удалить</Button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                            </Form.Group>
                            */}

                            <Form.Group>
                                <SortableList axis="y" items={order.media.photos} distance={1} onSortEnd={this.onSortEnd} />
                            </Form.Group>

                            <Gallery uploader={ this.uploader } />
                        </Col>
                    </Row>
                    <div className="bottom-panel active">
                        <div className="bottom-panel-control">
                            <Button type="submit" variant="success">Сохранить</Button>
                        </div>
                    </div>
                </Form>
            </Container>
            )

    }
}

export default Order