import React, {Component, Fragment} from "react";
import {
    Switch,
    Route,
    Link
} from "react-router-dom";
import {routerStructure} from "../config/Router";
import renderHTML from 'react-render-html';

class MainLayout extends Component {

    constructor(props) {

        super(props);
    }

    getNavLinkClass = path => {

        return this.props.location.pathname === path
            ? "is-current"
            : "";
    };

    render() {

        return (
            <>
                <div className="container-fluid">
                    { this.props.children }
                </div>
            </>
        );
    }
}

export default MainLayout;